<template>
  <v-container>
    <div>
      <v-btn color="primary" @click="back" class="noprint mr-3">
        <v-icon>
          mdi-arrow-left
        </v-icon>
      </v-btn>
    </div>
    <div class="mt-3">
      <iframe
        :src="url"
        frameborder="0"
        type="application/pdf"
        width="100%"
        height="800px"
      ></iframe>
    </div>
  </v-container>
</template>

<script>
import "jspdf-autotable";
import { jsPDF } from "jspdf";
import moment from "moment";

export default {
  name: "payment-voucher-purchase",
  props: {
    id: Number,
  },
  data() {
    return {
      purchasePayment: {},
      url: "",
    };
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    async getPurchasePayment() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("purchasePayment/getVoucherPayment", this.id)
        .then(response => {
          this.purchasePayment = response.data;
          this.createPdf();
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    formatPrice(value) {
      if (value === null) {
        return "";
      }
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    createListAccount() {
      let array = [];
      this.purchasePayment.accounts.forEach(x => {
        array.push([
          {
            content: `${x.accountName}\n${x.desc}`,
            styles: { valign: "middle", halign: "left" },
          },
          {
            content:
              this.formatPrice(x.debit) !== "" ? `${x.currency} ${this.formatPrice(x.debit)}` : "",
            styles: { valign: "middle", halign: "right" },
          },
          {
            content:
              this.formatPrice(x.credit) !== ""
                ? `${x.currency} ${this.formatPrice(x.credit)}`
                : "",
            styles: { valign: "middle", halign: "right" },
          },
          { content: this.formatPrice(x.debitIDR), styles: { valign: "middle", halign: "right" } },
          { content: this.formatPrice(x.creditIDR), styles: { valign: "middle", halign: "right" } },
        ]);
      });
      return array;
    },
    createPdf() {
      const doc = new jsPDF();

      doc.setFont("Times", "bold");
      doc.setFontSize(20);
      doc.text("PT. GRAHA SUMBER PRIMA ELEKTRONIK", 30, 20);
      doc.setFontSize(16);
      doc.text("PAYMENT VOUCHER", 80, 30);

      doc.setFont("Times", "normal");
      doc.setFontSize(10);
      doc.rect(30, 35, 6, 6);
      if (this.purchasePayment.method.split(",").includes("Giro")) {
        doc.text("X", 32, 39);
      }
      doc.text("GIRO", 41, 39);
      doc.rect(70, 35, 6, 6);
      if (this.purchasePayment.method.split(",").includes("Cash")) {
        doc.text("X", 72, 39);
      }

      doc.text("CASH", 81, 39);
      doc.rect(110, 35, 6, 6);
      if (this.purchasePayment.method.split(",").includes("Transfer")) {
        doc.text("X", 112, 39);
      }

      doc.text("TRANSFER", 121, 39);
      doc.text("NO : ", 161, 39);

      doc.rect(5, 45, 200, 10);
      doc.setFont("Times", "normal");
      doc.setFontSize(10);
      doc.text("VOUCHER NO.", 27, 51);
      doc.rect(55, 47, 30, 6);
      doc.text(this.purchasePayment.voucherNumber, 57, 51);

      doc.text("DATE :", 90, 51);
      doc.rect(105, 47, 20, 6);
      doc.text(moment(this.purchasePayment.date).format("DD-MM-yyyy"), 107, 51);

      doc.text("Company :", 137, 51);
      doc.text("GSPE", 160, 51);

      doc.autoTable({
        margin: { left: 5, right: 5 },
        startY: 55,
        theme: "plain",
        tableLineWidth: 0.1,
        tableLineColor: "black",
        columnStyles: {
          0: { cellWidth: 90 },
        },
        head: [
          [
            {
              rowSpan: 2,
              content: "ACCOUNT NAME",
              styles: { valign: "middle", halign: "center", lineWidth: 0.1, fontStyle: "bold" },
            },
            {
              colSpan: 2,
              content: "AMOUNT",
              styles: { valign: "middle", halign: "center", lineWidth: 0.1, fontStyle: "bold" },
            },
            {
              colSpan: 2,
              content: "EQUIVALEN IN RP",
              styles: { valign: "middle", halign: "center", lineWidth: 0.1, fontStyle: "bold" },
            },
          ],
          [
            {
              content: "Debit",
              styles: { valign: "middle", halign: "center", lineWidth: 0.1, fontStyle: "bold" },
            },
            {
              content: "Credit",
              styles: { valign: "middle", halign: "center", lineWidth: 0.1, fontStyle: "bold" },
            },
            {
              content: "Debit",
              styles: { valign: "middle", halign: "center", lineWidth: 0.1, fontStyle: "bold" },
            },
            {
              content: "Credit",
              styles: { valign: "middle", halign: "center", lineWidth: 0.1, fontStyle: "bold" },
            },
          ],
        ],
        body: [
          ...this.createListAccount(),
          [
            {
              content: "TOTAL",
              styles: { valign: "middle", halign: "center", lineWidth: 0.1, fontStyle: "bold" },
            },
            {
              content: `${
                this.purchasePayment.currency != "IDR"
                  ? ""
                  : this.formatPrice(this.purchasePayment.totalDebit)
              }`,
              styles: { valign: "middle", halign: "right", lineWidth: 0.1, fontStyle: "bold" },
            },
            {
              content: `${
                this.purchasePayment.currency != "IDR"
                  ? ""
                  : this.formatPrice(this.purchasePayment.totalCredit)
              }`,
              styles: { valign: "middle", halign: "right", lineWidth: 0.1, fontStyle: "bold" },
            },
            {
              content: `${this.formatPrice(this.purchasePayment.totalDebitIDR)}`,
              styles: { valign: "middle", halign: "right", lineWidth: 0.1, fontStyle: "bold" },
            },
            {
              content: `${this.formatPrice(this.purchasePayment.totalCreditIDR)}`,
              styles: { valign: "middle", halign: "right", lineWidth: 0.1, fontStyle: "bold" },
            },
          ],
        ],
        styles: {
          tableWidth: "auto",
          lineColor: "black",
          font: "times",
          fontSize: 9,
        },
        didDrawCell: data => {
          if (data.row.section === "body" && data.column.dataKey >= 0) {
            let x = data.cell.x + data.cell.width;
            let y = data.cell.y + data.cell.height;
            data.doc.setDrawColor(0);
            data.doc.line(x, data.cell.y, x, y);
          }
        },
      });

      const y2 = doc.lastAutoTable.finalY + 10;
      doc.setDrawColor(0);
      doc.rect(5, y2, 200, 40);
      doc.setFont("Times", "bold");
      doc.setFontSize(10);
      doc.text("Paid To :", 7, y2 + 5);
      doc.text(this.purchasePayment.customerName, 7, y2 + 10);
      doc.text("Received by :", 107, y2 + 5);
      doc.setFont("Times", "normal");
      doc.setFontSize(10);
      doc.text("(                                          )", 107, y2 + 35);
      doc.setFont("Times", "bold");
      doc.setFontSize(10);
      doc.text("Date : ", 157, y2 + 35);
      doc.setDrawColor(0);
      doc.line(167, y2 + 35, 193, y2 + 35);

      doc.autoTable({
        margin: { left: 5, right: 5 },
        startY: y2 + 45,
        theme: "plain",
        head: [["PREPARED BY :", "CHECKED BY :", "APPROVALS :"]],
        body: [
          [
            {
              content: "",
              styles: { valign: "middle", halign: "center", lineWidth: 0.1, fontStyle: "normal" },
            },
            {
              content: "",
              styles: { valign: "middle", halign: "center", lineWidth: 0.1, fontStyle: "normal" },
            },
            {
              content: "",
              styles: { valign: "middle", halign: "center", lineWidth: 0.1, fontStyle: "normal" },
            },
          ],
        ],
        styles: {
          valign: "middle",
          tableWidth: "auto",
          lineWidth: 0.1,
          halign: "left",
          lineColor: "black",
          minCellHeight: 30,
        },
        headStyles: {
          valign: "middle",
          halign: "center", //'center' or 'right',
          tableWidth: "auto",
          minCellHeight: 0,
        },
      });

      this.url = doc.output("datauristring");
    },
  },
  created() {
    this.getPurchasePayment();
  },
};
</script>

<style></style>
